import React, {useEffect} from 'react'
import {BsPlusCircle} from 'react-icons/bs'
import {useDispatch, useSelector} from 'react-redux'
import {actGetAccountBalance} from '../../../../app/redux/corporate/AccountBalanceActions'
import {
  RELOAD_ACCOUNT_BALANCE,
  SHOW_TOPUP_ACCOUNT_BALANCE,
} from '../../../../app/redux/corporate/CorporateActionTypes'
import {RootState} from '../../../../app/redux/rootReducer'
import {CurrencyFormat} from '../../../../app/helper/currencyFormat'

const AccountBalance: any = () => {
  const accountBalance = useSelector((state: RootState) => state.accountBalance)
  const dispatch = useDispatch()
  const {corporate_id} = useSelector((state: RootState) => state.auth.company)

  useEffect(() => {
    if (accountBalance.status === 'pending' && corporate_id) {
      dispatch(actGetAccountBalance({corporateid: corporate_id}))
    }
  }, [accountBalance.status, corporate_id, dispatch])

  useEffect(() => {
    if (corporate_id) {
      dispatch({type: RELOAD_ACCOUNT_BALANCE})
    }
  }, [corporate_id, dispatch])
  const credits: number = parseFloat(accountBalance.credits.toString()) || 0
  const creditString = credits
  return (
    <div className='d-flex align-items-center justify-content-end '>
      <div className='account-balance-wrapper'>
        <div className='account-fund-label' style={{marginBottom: '2px', marginTop: '2px'}}>
          Account Funds
        </div>
        <div
          className='account-balance vertical-align'
          onClick={() => dispatch({type: SHOW_TOPUP_ACCOUNT_BALANCE, payload: {show: true}})}
        >
          <CurrencyFormat value={creditString} />
        </div>
        <div
          className='caret-indicator'
          style={{
            position: 'absolute',
            right: '5px',
            top: '0',
            bottom: '0',
            margin: 'auto',
            cursor: 'pointer',
          }}
          onClick={() => dispatch({type: SHOW_TOPUP_ACCOUNT_BALANCE, payload: {show: true}})}
        >
          <BsPlusCircle size={16} className='text-primary' />
        </div>
      </div>
    </div>
  )
}

export default AccountBalance
