import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {Modal, ModalBody, ModalHeader} from 'reactstrap'
import {getModal} from '../../../../app/redux/modal/ModalActions'
import {RootState} from '../../../../app/redux/rootReducer'
import {BsCheck, BsX} from 'react-icons/bs'
import './ModalSendGift.scss'
import {FaCircleCheck, FaCircleXmark} from 'react-icons/fa6'
import {FaCheckSquare} from 'react-icons/fa'

interface MenuActProps {
  title: string
  subtitle: string
  label: string
  active: boolean
  id: string
  pros: string[]
  cons: string[]
  recommended: boolean
  onClick: () => void
}

export default function ModalSendGift() {
  const modal: any = useSelector((state: any) => state.modal)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {corporate_detail} = useSelector((state: RootState) => state.auth.company)
  const strapi = useSelector((state: RootState) => state.strapi)

  const {MODAL_SEND_GIFT} = modal

  const handleModal = () => {
    return dispatch(getModal({modal: 'MODAL_SEND_GIFT', open: false}))
  }

  const [options, setOptions] = useState<MenuActProps[]>([
    {
      title: 'SEND DIGITAL GIFTS',
      subtitle: 'AUTO DELIVERY',
      label: 'Recommended',
      active: true,
      id: 'send-gift',
      pros: [
        'Instant processing',
        'Giftano handles sending directly to recipients',
        'Can personalise recipient’s name and message',
        'Can schedule for each recipient',
        'Paperless and environmentally friendly',
      ],
      cons: [],
      recommended: true,
      onClick: () => {
        navigate('/send-gifts/create-order')
        handleModal()
      },
    },
    {
      title: 'BUY GIFT LINK(S)',
      subtitle: 'EXCEL FILE',
      label: '',
      active: true,
      id: 'buy-link',
      pros: ['Instant processing', 'Paperless and environmentally friendly'],
      cons: [
        'Unable to customise each e-message',
        'Requires manual sending',
        'You must distribute links manually',
      ],
      recommended: false,
      onClick: () => {
        navigate('/send-gifts/buy-link')
        handleModal()
      },
    },
    {
      title: 'BUY GIFT(S) VIA ORDER FORM',
      subtitle: 'UPLOAD EXCEL FILE',
      label: '',
      active: true,
      id: 'manual-order',
      pros: [
        'Can personalise recipient’s name and message',
        'Can schedule for each recipient',
        'Bulk corporate gifting with specific requirements',
      ],
      cons: ['Longer processing time', 'More steps involved'],
      recommended: false,
      onClick: () => {
        navigate('/send-gifts/manual-order')
        handleModal()
      },
    },
    // {
    //   title: 'PHYSICAL GIFT CARD(S)',
    //   subtitle: 'MAGIC CARD',
    //   label: '',
    //   active: true,
    //   id: 'qr-gift',
    //   pros: ['Branded printing available', 'Recommended for in-person events'],
    //   cons: ['Longer processing time', 'Requires physical handling', 'Not eco-friendly'],
    //   recommended: false,
    //   onClick: () => {
    //     navigate('/about-magic-card')
    //     handleModal()
    //   },
    // },
  ])

  useEffect(() => {
    let sgbutton = options
    if (strapi && strapi.data) {
      if (strapi?.data.enable_buy_link) {
        sgbutton = sgbutton.map((item) => (item.id === 'buy-link' ? {...item, active: true} : item))
      }
    }
    if (
      corporate_detail &&
      corporate_detail['transaction-settings'] &&
      corporate_detail['transaction-settings']['gfb-enable-buy-links']
    ) {
      sgbutton = sgbutton.map((item) =>
        item.id === 'buy-link'
          ? {
              ...item,
              active: corporate_detail['transaction-settings']['gfb-enable-buy-links'] === '1',
            }
          : item
      )
    } else {
      sgbutton = sgbutton.map((item) => (item.id === 'buy-link' ? {...item, active: false} : item))
    }
    setOptions(sgbutton)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strapi, corporate_detail])

  const handleSendGiftModal = () => {
    dispatch(getModal({modal: 'MODAL_SEND_GIFT', open: true}))
  }

  if (
    corporate_detail.type !== 'partnership' &&
    corporate_detail.is_api_account?.toString() !== '1' &&
    corporate_detail['transaction-settings']['gfb-disable-order'] !== '1' &&
    options &&
    options.filter((item) => item.active === true).length > 0
  ) {
    return (
      <div>
        <button className='btn btn-warning me-3' onClick={handleSendGiftModal}>
          Send Gift
        </button>
        <Modal className='modal-send-gift' isOpen={MODAL_SEND_GIFT} toggle={handleModal}>
          <ModalHeader toggle={handleModal}>Send/Create Gift(s)</ModalHeader>

          <ModalBody>
            <div className='send-gift-options d-flex justify-content-center flex-wrap'>
              {options.map((item, index) => (
                <div
                  key={index}
                  className='send-gift-option card mx-2 mb-3'
                  style={{width: '22%', minWidth: '200px'}}
                >
                  <div className='card-body text-center'>
                    <div className='title-contain'>
                      <h5 className='card-title'>{item.title}</h5>
                      <p className='card-subtitle text-muted'>
                        <span>{item.subtitle}</span>
                      </p>
                    </div>

                    <div className='contain'>
                      {item.label && (
                        <>
                          {/* <hr /> */}
                          <div className={`labeling ${item.recommended ? '' : 'bg-danger'} py-4`}>
                            {item.recommended ? (
                              <FaCircleCheck className='me-2' color='var(--gf-success-300)' />
                            ) : (
                              <FaCircleXmark className='me-2' color='var(--gf-danger-300)' />
                            )}
                            {item.label}
                          </div>
                        </>
                      )}
                      {!item.label && <hr />}
                      <div className='pros-cons text-start'>
                        {item.pros.map((pro, idx) => (
                          <div key={`pro-${idx}`} className=' mb-1'>
                            <FaCheckSquare className='icon-size text-success me-2' />
                            <small>{pro}</small>
                          </div>
                        ))}
                        {item.cons.map((con, idx) => (
                          <div key={`con-${idx}`} className=' mb-1'>
                            <FaCircleXmark className='icon-size text-danger me-2' />
                            <small>{con}</small>
                          </div>
                        ))}
                      </div>
                    </div>
                    <button
                      className='btn btn-dark w-100 mt-3'
                      onClick={item.onClick}
                      disabled={!item.active}
                    >
                      Order Now
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </ModalBody>
        </Modal>
      </div>
    )
  } else {
    return <></>
  }
}
