import React, {FC, useEffect, useState} from 'react'
// import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {Button, Col, FormGroup, FormText, Input, Label, Row} from 'reactstrap'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import ModalUen from './ModalUen'

import {GiForm} from '../../components/formGi/FormGi'
import {RootState} from '../../redux/rootReducer'
import {ActPostRegisterCorporate} from '../../redux/corporate/CorporateActions'

import {messages} from '../../Messages'
import {getModal} from '../../redux/modal/ModalActions'
import PhoneInput from 'react-phone-input-2'
import Select from 'react-select'
import {getCountryProfile} from '../../services/country/CountryServices'

// import {ERROR_CORPORATE} from '../../redux/corporate/CorporateActionTypes'
import './style.scss'
import LayoutConfirm from '../../components/confirm/LayoutConfirm'
import {useNavigate} from 'react-router-dom'

const FormRegister: FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const modulCorporate = useSelector((state: RootState) => state.modulCorporate)
  const [modalKonfirmationRegister, setModalKonfirmationRegister] = useState<boolean>(false)
  const [viewMode, setViewMode] = useState<'profile-info' | 'company-info' | 'verify-otp'>(
    'profile-info'
  )
  const [tempSuccess, setTempSuccess] = useState<boolean>(false)

  useEffect(() => {
    if (modulCorporate.status === 'exsits') {
      dispatch(getModal({modal: 'MODAL_EXISTS', open: true}))
    }
  }, [dispatch, modulCorporate.status])

  const [optCountry, setOptCountry] = useState([])
  const [loadCountry, setLoadCountry] = useState<boolean>(false)

  useEffect(() => {
    const getCountry = async () => {
      setLoadCountry(true)
      try {
        const rest = await getCountryProfile()
        const {code, data} = rest
        if (code === 200) {
          const datas = data.map((item: any) => {
            return {
              label: item.country_name,
              value: item.code,
            }
          })

          setOptCountry(datas)
        }
      } catch (error) {}

      setLoadCountry(false)
    }

    getCountry()
  }, [])

  // giform config
  const {errors, GiFormSubmit, giData, GiHandleChange, GiNameAndValue} = GiForm({
    fields: [
      {
        name: 'fullname',
        validations: ['required'],
        messages: {
          required: messages.register_fullname_required,
        },
      },
      {
        name: 'email',
        validations: ['required', 'email'],
        messages: {
          required: messages.register_email_required,
        },
      },
      {
        name: 'phonenumber',
        validations: ['required', 'number'],
        messages: {
          required: messages.register_phone_required,
        },
      },
      {
        name: 'businessName',
        validations: ['required'],
        messages: {
          required: messages.register_business_name_required,
        },
      },
      {
        name: 'department',
        validations: [],
        messages: {
          required: messages.register_business_name_required,
        },
      },
      {
        name: 'country',
        validations: ['required'],
        messages: {
          required: messages.register_country_required,
        },
        defaultValue: 'sg',
      },
    ],
    onSubmited: (values: any, isSubmited: boolean, errors: any) => {
      const {fullname, email, phonenumber, businessName, department, company_phone, country} =
        values
      if (isSubmited) {
        let data = {
          details: {
            personal_profile: {
              name: fullname,
              alias_name: '',
              email: email,
              mobile_phone: phonenumber,
            },
            company_profile: {
              business_name: businessName,
              department: department,
              mobile_phone: company_phone,
              country: country.toUpperCase(),
            },
            with_singpass: false,
          },
          auto_approved: false,
        }
        // console.log(data)
        // return

        return dispatch(ActPostRegisterCorporate(data))
      }
    },
  })

  const changePhoneNumber = (name: any, value: any) => {
    GiNameAndValue(name, value)
  }

  // const changePhoneNumberCompany = (name: any, value: any) => {
  //   GiNameAndValue('name', value)
  // }

  const changeCountry = (name: any, value: any) => {
    GiNameAndValue(name, value)
  }

  // let navigate = useNavigate()

  const selected = (data: any = [], value: string = '') => {
    const val: any = data.filter((item: any) => item.value === value.toUpperCase())
    return val[0] || {label: 'Please Select Country', value: ''}
  }

  const [country_phone_number, setCountry_phone_number] = useState<any>('')
  return (
    <div>
      <ModalUen />

      {modulCorporate.status !== 'success' && !tempSuccess ? (
        viewMode === 'verify-otp' ? (
          <>
            <Formik
              initialValues={{
                otp: '',
              }}
              validationSchema={Yup.object({
                otp: Yup.string()
                  .length(6, 'OTP must be exactly 6 digits')
                  .required('OTP is required'),
              })}
              onSubmit={(values, {setSubmitting}) => {
                console.log('Submitted OTP:', values.otp)
                setViewMode('company-info')
                setSubmitting(false)
                // Handle OTP verification logic here
              }}
            >
              {({isSubmitting, values, setFieldValue}) => (
                <Form className='form-shady'>
                  <h4 className='mb-9 mt-0 text-center'>OTP Verification</h4>

                  {/* OTP Input */}
                  <FormGroup row className='mb-6'>
                    <Col sm='2' md='3' lg='3'>
                      <Label>Enter OTP *</Label>
                    </Col>
                    <Col sm='10' md='9' className='d-flex flex-column'>
                      <div className='d-flex justify-content-between'>
                        {[...Array(6)].map((_, index) => (
                          <Field
                            key={index}
                            name={`otp${index}`}
                            type='text'
                            maxLength={1}
                            className='form-control text-center otp-input'
                            style={{width: '40px', marginRight: index < 5 ? '5px' : '0'}}
                            value={values.otp[index] || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              const newOtp = values.otp.split('')
                              newOtp[index] = e.target.value
                              setFieldValue('otp', newOtp.join(''))
                              if (e.target.value.length === 1 && index < 5) {
                                const nextInput = e.target.nextElementSibling as HTMLInputElement
                                nextInput?.focus()
                              }
                            }}
                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                              if (e.key === 'Backspace' && !values.otp[index] && index > 0) {
                                const prevInput = (e.target as HTMLInputElement)
                                  .previousElementSibling as HTMLInputElement | null
                                prevInput?.focus()
                              }
                            }}
                            onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
                              const pasteData = e.clipboardData.getData('text').slice(0, 6)
                              setFieldValue('otp', pasteData.padEnd(6, ''))
                              const inputs = Array.from(
                                e.currentTarget.parentElement?.querySelectorAll('input') || []
                              ) as HTMLInputElement[]
                              inputs.forEach((input, idx) => {
                                input.value = pasteData[idx] || ''
                                if (pasteData[idx] && inputs[idx + 1]) {
                                  inputs[idx + 1].focus()
                                }
                              })
                              e.preventDefault()
                            }}
                          />
                        ))}
                      </div>
                      <ErrorMessage name='otp' component={FormText} className='error-message' />
                    </Col>
                  </FormGroup>

                  {/* Submit Button */}
                  <FormGroup className='text-center'>
                    <Button
                      type='submit'
                      className='mt-5 w-40 btn btn-rounded rounded-pill'
                      color='primary'
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Verifying...' : 'Verify OTP'}
                    </Button>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </>
        ) : viewMode === 'company-info' ? (
          <>
            <Formik
              initialValues={{
                businessName: '',
                department: '',
                country: '',
                company_phone: '',
              }}
              validationSchema={Yup.object({
                businessName: Yup.string().required('Company Name is required'),
                country: Yup.string().required('Country is required'),
                company_phone: Yup.string().required('Phone number is required'),
              })}
              onSubmit={(values, {setSubmitting}) => {
                console.log('Submitted Data:', values)
                setTempSuccess(true)
                setSubmitting(false)
              }}
            >
              {({values, setFieldValue, isSubmitting}) => (
                <Form>
                  {/* Company Name */}
                  <h4 className='mb-9 mt-0 text-center'>Company Information</h4>
                  <FormGroup row>
                    <Col sm='2' md='3' lg='3'>
                      <Label>Company Name *</Label>
                    </Col>
                    <Col sm='10' md='9'>
                      <Field
                        name='businessName'
                        placeholder='Enter Company Name'
                        className='form-control'
                      />
                      <ErrorMessage name='businessName' component={FormText} />
                    </Col>
                  </FormGroup>

                  {/* Department */}
                  <FormGroup row>
                    <Col sm='2' md='3' lg='3'>
                      <Label>Department</Label>
                    </Col>
                    <Col sm='10' md='9'>
                      <Field
                        name='department'
                        placeholder='Enter Department'
                        className='form-control'
                      />
                      <ErrorMessage name='department' component={FormText} />
                    </Col>
                  </FormGroup>

                  {/* Country */}
                  <FormGroup row>
                    <Col sm='2' md='3' lg='3'>
                      <Label>Country *</Label>
                    </Col>
                    <Col sm='10' md='9'>
                      <Select
                        options={optCountry}
                        isLoading={loadCountry}
                        isDisabled={loadCountry}
                        value={selected(optCountry, values.country)}
                        classNamePrefix='register-b2b'
                        onChange={(option) => setFieldValue('country', option.value)}
                        styles={{
                          control: (styles) => ({
                            ...styles,
                            borderColor: '#C4C4C4',
                          }),
                        }}
                      />
                      <ErrorMessage name='country' component={FormText} />
                    </Col>
                  </FormGroup>

                  {/* Company Phone Number */}
                  {/* <FormGroup row>
                    <Col sm='2' md='3' lg='3'>
                      <Label>Company Phone Number *</Label>
                    </Col>
                    <Col sm='10' md='9'>
                      <PhoneInput
                        autoFormat={false}
                        countryCodeEditable={false}
                        country={country_phone_number || giData.country}
                        onChange={(value, country: any) => {
                          const {countryCode} = country
                          setCountry_phone_number(countryCode)
                          changePhoneNumber('company_phone', value)
                        }}
                        inputProps={{
                          name: 'company_phone',
                          className: 'form-control',
                        }}
                      />
                      <ErrorMessage name='company_phone' component={FormText} />
                    </Col>
                  </FormGroup> */}

                  {/* Submit Button */}
                  <FormGroup className='text-center'>
                    <Button
                      type='submit'
                      className='mt-5 w-40 btn btn-rounded rounded-pill'
                      color='primary'
                      disabled={modulCorporate.status === 'loading' || isSubmitting}
                    >
                      {modulCorporate.status === 'loading' ? 'Loading...' : 'Create Account'}
                    </Button>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </>
        ) : (
          <Formik
            initialValues={{
              fullname: '',
              email: '',
              phonenumber: '',
            }}
            validationSchema={Yup.object({
              fullname: Yup.string().required('Full name is required'),
              email: Yup.string().email('Invalid email').required('Email is required'),
              phonenumber: Yup.string().required('Phone number is required'),
            })}
            onSubmit={(values, {setSubmitting}) => {
              console.log('Submitted Data:', values)
              setSubmitting(false)
              if (values.email === 'zelva@giftano.com') {
                setModalKonfirmationRegister(true)
                return
              }
              setViewMode('verify-otp')
            }}
          >
            {({values, setFieldValue, isSubmitting}) => (
              <Form className='form-shady'>
                <h4 className='mb-9 mt-0 text-center'>Profile Information</h4>

                {/* Full Name */}
                <FormGroup row className='mb-6'>
                  <Col sm='2' md='3' lg='3'>
                    <Label>Full Name *</Label>
                  </Col>
                  <Col sm='10' md='9'>
                    <Field name='fullname' placeholder='Enter Full Name' className='form-control' />
                    <ErrorMessage name='fullname' component={FormText} />
                  </Col>
                </FormGroup>

                {/* Email */}
                <FormGroup row className='mb-6'>
                  <Col sm='2' md='3' lg='3'>
                    <Label>Email Address *</Label>
                  </Col>
                  <Col sm='10' md='9'>
                    <Field
                      name='email'
                      type='email'
                      placeholder='john.doe@yourcompany.com'
                      className='form-control'
                    />
                    <ErrorMessage name='email' component={FormText} className='error-message' />
                  </Col>
                </FormGroup>

                {/* Phone Number */}
                <FormGroup row className='mb-6'>
                  <Col sm='2' md='3' lg='3'>
                    <Label>Phone Number *</Label>
                  </Col>
                  <Col sm='10' md='9' className='phone-register-b2b'>
                    <PhoneInput
                      autoFormat={false}
                      countryCodeEditable={false}
                      country='sg'
                      value={values.phonenumber}
                      onChange={(value) => setFieldValue('phonenumber', value)}
                      inputProps={{
                        name: 'phonenumber',
                        className: 'form-control',
                      }}
                    />
                    <ErrorMessage
                      name='phonenumber'
                      component={FormText}
                      className='error-message'
                    />
                  </Col>
                </FormGroup>

                {/* Submit Button */}
                <FormGroup className='text-center'>
                  <Button
                    type='submit'
                    className='mt-5 w-40 w-full btn btn-rounded rounded-pill'
                    color='primary'
                    disabled={modulCorporate.status === 'loading' || isSubmitting}
                  >
                    {modulCorporate.status === 'loading' ? 'Loading...' : 'Next'}
                  </Button>
                </FormGroup>
              </Form>
            )}
          </Formik>
        )
      ) : (
        <>
          <h2 className='text-center titling'>THANK YOU</h2>
          <div className='text-center'>
            <p className='subline'>Thank you for creating an account with us.</p>
            <p className='subline'>
              We have received your request and will come back to you shortly.
            </p>
            <p className='subline'>
              This typically takes a few hours but will often happen much more quickly. No further
              action is required on your part. Once we have complete our review your information, we
              will proceed to set up your account.
            </p>
            <p className='subline'>We appreciate your patience,</p>
            <span className='text-primary font-16-bold'>Team Giftano</span>
          </div>
        </>
      )}

      <LayoutConfirm
        isOpen={modalKonfirmationRegister}
        title={'Email Already Registered'}
        message={
          'The email you entered is already registered. Would you like to proceed with registering a new company or sing in to your existing account?'
        }
        onAccept={() => {
          setModalKonfirmationRegister(false)
          navigate('/login')
        }}
        onClose={() => {
          setModalKonfirmationRegister(false)
          setViewMode('company-info')
        }}
        textAccept='Sign In'
        textClose='Register New Company'
        buttonCloseProps={{
          style: {
            width: '200px',
          },
        }}
      />
    </div>
  )
}

export default FormRegister
