/* eslint-disable eqeqeq */
import {Modal, ModalBody, ModalHeader} from 'reactstrap'
import {GiButton} from '../../../../app/components/system-ui'
import {GoVerified} from 'react-icons/go'
import {useLocation, useNavigate} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../app/redux/rootReducer'
import {getModal} from '../../../../app/redux/modal/ModalActions'

export default function ModalVerifyCompany() {
  const [modalShow, setModalShow] = useState(false)
  const dispatch = useDispatch()
  const toggle = () => {
    setModalShow(false)
    dispatch(getModal({modal: 'MODAL_VERIFY_COMPANY', open: false}))
  }
  const {corporate_detail} = useSelector((state: RootState) => state.auth.company)
  const modal: any = useSelector((state: any) => state.modal)
  const {MODAL_VERIFY_COMPANY} = modal
  const location = useLocation()

  useEffect(() => {
    if (
      corporate_detail.is_verified == 0 &&
      corporate_detail.type === 'corporate' &&
      location.pathname !== '/profile-detail' &&
      location.pathname !== '/settings'
    ) {
      setModalShow(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [corporate_detail])

  const navigate = useNavigate()
  const handleSubmitVerify = () => {
    navigate('/profile-detail')
  }
  return (
    <Modal
      className='modal-company__verify'
      isOpen={modalShow || MODAL_VERIFY_COMPANY}
      toggle={toggle}
      centered
    >
      <ModalHeader toggle={toggle}></ModalHeader>
      <ModalBody>
        <div className='modal-logo mb-4'>
          <GoVerified color='var(--gf-primary-300)' size='30px' />
        </div>
        <h3 className='mb-3'>Verify your business account to access more feature</h3>
        <p className='text-center'>
          Unverified accounts will have limited access and payment options. To verify your account,
          complete your company profile and submit a verification request from this page.
        </p>
        <GiButton
          type='submit'
          color='primary'
          size='md'
          className='rounded-pill w-50 mb-4'
          onClick={toggle}
          onClickCapture={() => handleSubmitVerify()}
        >
          Verify now
        </GiButton>
      </ModalBody>
    </Modal>
  )
}
