import {Dispatch} from 'react'
import {IAction} from '../store'
import {
  ADD_ITEM_CREATE_ORDER,
  BULK_UPDATE_ITEM_CREATE_ORDER,
  DELETE_ITEM_CREATE_ORDER,
  RESET_CREATE_ORDER,
  SET_DATA_TO_EDIT,
  SET_DETECTED_CHANGES,
  UPDATE_ITEM_CREATE_ORDER,
} from './CreateOrderActionTypes'

export const AddToCreateOrder = (data: any[]) => (dispatch: Dispatch<IAction>) => {
  dispatch({type: ADD_ITEM_CREATE_ORDER, payload: {data}})
}

export const UpdateCreateOrder = (data: any) => (dispatch: Dispatch<IAction>) => {
  dispatch({type: UPDATE_ITEM_CREATE_ORDER, payload: {data}})
}

export const BulkUpdateCreateOrder = (data: any[]) => (dispatch: Dispatch<IAction>) => {
  dispatch({type: BULK_UPDATE_ITEM_CREATE_ORDER, payload: {data}})
}

export const DeleteCreateOrder = (data: any) => (dispatch: Dispatch<IAction>) => {
  dispatch({type: DELETE_ITEM_CREATE_ORDER, payload: {data}})
}

export const SetDataToEdit = (data: any) => (dispatch: Dispatch<IAction>) => {
  dispatch({type: SET_DATA_TO_EDIT, payload: {data}})
}

export const ResetCreateOrder = () => (dispatch: Dispatch<IAction>) => {
  dispatch({type: RESET_CREATE_ORDER})
}

export const SetDetectedChanges = (data: boolean) => (dispatch: Dispatch<IAction>) => {
  dispatch({type: SET_DETECTED_CHANGES, payload: data})
}
