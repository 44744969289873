/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {Badge, UncontrolledTooltip} from 'reactstrap'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import AccountBalance from '../../../partials/layout/header-menus/AccountBalance'
import {Companies} from '../../../partials/layout/header-menus/Companies'
import {useLayout} from '../../core'
import {Header} from './Header'
import {DefaultTitle} from './page-title/DefaultTitle'
import {Topbar} from './Topbar'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../app/redux/rootReducer'
import {ActGetListAvailableCountry} from '../../../../app/redux/available-country/AvailableCountryActions'
import Select, {components} from 'react-select'
import CountryJson from '../../../../app/assets/json/country.json'
import {getModal} from '../../../../app/redux/modal/ModalActions'
import ModalSendGift from './ModalSendGift'

export function HeaderWrapper() {
  const {config, classes, attributes} = useLayout()
  const {header, aside} = config
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const availableCountryModule = useSelector((state: RootState) => state.availableCountry)
  const {corporate_id, corporate_detail} = useSelector((state: RootState) => state.auth.company)
  const CreateOrderModule = useSelector((state: RootState) => state.createOder)
  const [countryCatalogueSelected, setCountryCatalogueSelected] = useState<any>({
    label: 'Singapore',
    value: 'SG',
  })

  const {Option} = components

  const CustomOption = (props: any) => {
    return (
      <Option {...props}>
        <img
          src={require(`../../../../../public/media/flags/${props?.data?.value
            ?.replace(' ', '-')
            .toLowerCase()}.svg`)}
          alt={props?.data?.label}
          style={{width: '20px', marginRight: '10px', border: '1px solid #ececed'}}
          id={`country-flag-options-${props.data.value}`}
        />
        <span className='selected-flag-label'>{props.data.label}</span>
        <UncontrolledTooltip target={`country-flag-options-${props.data.value}`}>
          {props.data.label}
        </UncontrolledTooltip>
      </Option>
    )
  }

  const CustomSingleValue = (props: any) => {
    return (
      <div className='selected-flag'>
        <img
          id={`country-flag-${props?.data?.value}`}
          src={require(`../../../../../public/media/flags/${props?.data?.value
            ?.replace(' ', '-')
            .toLowerCase()}.svg`)}
          alt={props?.data?.label}
          style={{width: 'auto', height: '15px'}}
        />

        <UncontrolledTooltip target={`country-flag-${props?.data?.value}`}>
          {props.data.label}
        </UncontrolledTooltip>
      </div>
    )
  }

  useEffect(() => {
    if (availableCountryModule.status === 'pending') {
      dispatch(ActGetListAvailableCountry())
    } else if (availableCountryModule.status === 'success') {
      let cur_country
      if (corporate_detail?.country) {
        let selectCountry: any = availableCountryModule.result.find((country: any) => {
          return country.code === corporate_detail.country
        })
        if (selectCountry?.code) {
          cur_country = {
            value: selectCountry?.code,
            label: selectCountry?.country_name,
          }
        } else {
          let countryFromJson = CountryJson.find((country: any) => {
            return country.alpha2 === corporate_detail.country
          })
          if (countryFromJson?.name) {
            cur_country = {
              value: countryFromJson?.alpha2,
              label: countryFromJson?.name,
            }
          }
        }
        if (cur_country) {
          setCountryCatalogueSelected(cur_country)
        }
      }
    }
  }, [availableCountryModule.status, dispatch, corporate_id, corporate_detail])

  const handleCountryChange = (selectedOption: any) => {
    // setCountryCatalogueSelected(selectedOption)
    if (corporate_detail?.country?.toLowerCase() !== selectedOption?.value?.toLowerCase()) {
      if (CreateOrderModule?.detectedChanges) {
        dispatch(
          getModal({
            modal: 'MODAL_WARNING_CLOSE_ORDER',
            open: true,
            data: {to: `/catalogue?country=${selectedOption.value}&m=1`},
          })
        )
      } else {
        navigate(`/catalogue?country=${selectedOption.value}&m=1`)
      }
    }
  }

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      {...attributes.headerMenu}
    >
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-stretch justify-content-between'
        )}
      >
        {/* begin::Aside mobile toggle */}
        {aside.display && (
          <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
            <div
              className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
              id='kt_aside_mobile_toggle'
            >
              <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x mt-1' />
            </div>
          </div>
        )}
        {/* end::Aside mobile toggle */}
        {/* begin::Logo */}
        {!aside.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
            <Link to='/dashboard' className='d-lg-none'>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-2.svg')} className='h-30px' />
            </Link>
          </div>
        )}
        {/* end::Logo */}

        {aside.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
            <Link to='/' className='d-lg-none'>
              <img
                alt='Logo'
                src={'https://static-cdn.giftano.com/assets/giftano-logo-horizontal.png'}
                className='h-30px'
              />
            </Link>
          </div>
        )}

        <div className='custom-tool-mobile d-none'>
          <Companies />
          <AccountBalance />
        </div>

        {/* begin::Wrapper */}
        <div className='d-flex  align-items-stretch justify-content-between flex-lg-grow-1'>
          {/* begin::Navbar */}
          {header.left === 'menu' && (
            <div className='d-flex align-items-stretch' id='kt_header_nav'>
              <Header />
            </div>
          )}

          {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <DefaultTitle />
            </div>
          )}

          <div
            className='d-flex align-items-left my-custom-toolbar d-none me-4'
            style={{width: '-webkit-fill-available'}}
          >
            <div className='d-flex align-items-center'>
              <ModalSendGift />
            </div>

            {/* <AccountBalance /> */}

            {/* <div
              className='d-flex justify-content-center align-items-center'
              style={{paddingLeft: '20px', paddingRight: '10px'}}
            >
              <Link to={'/faq'} title={"FAQ's"} style={{fontWeight: 'bold', fontSize: '20px'}}>
                <BsQuestionCircle />
              </Link>
            </div> */}
          </div>

          <div className='d-flex '>
            {process.env.REACT_APP_BUILD_MODE === 'SANDBOX' && (
              <div className='d-flex align-items-center me-4'>
                <Badge className='env-indicator' color='danger'>
                  SANDBOX ENV
                </Badge>
              </div>
            )}
            <Companies />
            <div className='contry-selector-wrapper'>
              <Select
                className='country-selector'
                options={availableCountryModule.result.map((country: any) => ({
                  value: country.code,
                  label: country.country_name,
                }))}
                isSearchable={false}
                value={countryCatalogueSelected}
                onChange={handleCountryChange}
                name='country'
                components={{
                  IndicatorSeparator: () => null,
                  Option: CustomOption,
                  SingleValue: CustomSingleValue,
                }}
                styles={{
                  control: (styles: any) => ({
                    ...styles,
                    height: '28px',
                    minHeight: '28px',
                    backgroundColor: '#ececed',
                    borderRadius: '6px',
                    border: 'none',
                    boxShadow: 'none',
                  }),
                  dropdownIndicator: (styles: any) => ({
                    ...styles,
                    padding: '0px 6px 0px 0px',
                    maxHeight: '28px',
                  }),
                  valueContainer: (styles: any) => ({
                    ...styles,
                    height: '28px',
                    padding: '0px 6px',
                    display: 'flex',
                  }),
                  indicatorsContainer: (styles: any) => ({
                    ...styles,
                    height: '28px',
                  }),
                  input: (styles: any) => ({
                    ...styles,
                    display: 'none',
                  }),
                  menuList: (styles: any) => ({
                    ...styles,
                    borderRadius: '8px',
                    padding: '16px 4px',
                  }),
                  menu: (styles: any) => ({
                    ...styles,
                    padding: '0px',
                  }),
                }}
              />
            </div>
            <Topbar />
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
  )
}
