import {useDispatch, useSelector} from 'react-redux'
import LayoutConfirm from '../confirm/LayoutConfirm'
import {getModal} from '../../redux/modal/ModalActions'
import {useNavigate} from 'react-router-dom'
import {ResetCreateOrder} from '../../redux/create-order/CreateOrderActions'
import {logoutAct} from '../../redux/auth'

const ModalWarningCloseOrder = () => {
  const navigate = useNavigate()
  const modal: any = useSelector((state: any) => state.modal)
  const {MODAL_WARNING_CLOSE_ORDER, data} = modal
  const dispatch = useDispatch()
  const toggle = () => {
    dispatch(getModal({modal: 'MODAL_WARNING_CLOSE_ORDER', open: false, data: {}}))
  }

  return (
    <LayoutConfirm
      isOpen={MODAL_WARNING_CLOSE_ORDER}
      onClose={() => toggle()}
      onAccept={() => {
        toggle()
        dispatch(ResetCreateOrder())
        if (data?.to) {
          navigate(data?.to)
        } else if (data?.reload) {
          window.location.reload()
        } else if (data?.logout) {
          dispatch(logoutAct())
        }
      }}
      title={'Unsaved Changes'}
      message={
        <>
          You have unsaved changes in your order. Are you sure you want to leave without saving?{' '}
          <br />
          Your changes will be lost.
        </>
      }
    />
  )
}

export default ModalWarningCloseOrder
